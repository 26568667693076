import axios from "axios"
import { getFirstName, getLastName } from "../../../utll"

export const submitContactForm = contactFormData => {
  const hubSpotData = {
    portalId: "24963397",
    formId: "3b48fa98-8847-4fb0-8c5e-ef86a7beeb23",
  }

  const contactFormURI = `https://api.hsforms.com/submissions/v3/integration/submit/${hubSpotData.portalId}/${hubSpotData.formId}`
  const body = createContactFormData(
    contactFormData.name,
    contactFormData.phone,
    contactFormData.email,
    contactFormData.comment
  )

  return axios
    .post(contactFormURI, body)
    .then(response => {
      return { status: response.status, message: "Success" }
    })
    .catch(error => {
      return { status: 400, message: error.message }
    })
}

const createContactFormData = (name, phone, email, comment = "") => {
  return {
    submittedAt: Date.now(),
    fields: [
      {
        name: "firstname",
        value: getFirstName(name),
      },
      {
        name: "lastname",
        value: getLastName(name),
      },
      {
        name: "phone",
        value: phone,
      },
      {
        name: "email",
        value: email,
      },
      {
        name: "message",
        value: comment,
      },
      {
        name: "hs_lead_status",
        value: "New",
      },
    ],
    context: {
      pageUri: "https://www.sacoff.org",
      pageName: "Contact Page",
    },
  }
}
