import React from "react"
import "./cta.css"
import Link from "gatsby-link"

interface CTAProps {}

const CTA: React.FC<CTAProps> = ({}) => {
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="text-center">
          <h3>Become a Member</h3>
          <Link className="cta-btn" to="/membership">
            Join Now
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CTA
