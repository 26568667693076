import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/About/about"
import Contact from "../components/Contact/contact"
import CTA from "../components/CTA/cta"
import Hero from "../components/Hero/hero"

const links = [
  { displayName: "About", link: "#about" },
  { displayName: "Contact", link: "#contact" },
  { displayName: "Press", link: "/press" },
]
const IndexPage = () => (
  <Layout navigationLinks={links}>
    <Seo title="Home" />
    <Hero />
    <main id="main">
      <About />
      <CTA />
      <Contact />
    </main>
  </Layout>
)

export default IndexPage
