import * as Yup from "yup"

export const contactFormSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/\w\s\w/, "please include surname too")
    .min(1, "too short!")
    .required("required"),
  comment: Yup.string().min(5, "too short!"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "please enter a valid 10 digit number")
    .min(10, "please enter a valid 10 digit number")
    .max(10, "please enter a valid 10 digit number")
    .required("required"),
  email: Yup.string().email("invalid email").required("required"),
  emailConfirm: Yup.string()
    .email("invalid email")
    .test("is-equal", "email address is not the same", (value, context) => {
      return value === context.parent.email
    })
    .required("required"),
})
