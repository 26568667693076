import React from "react"
import "./contact.css"
import { useStaticQuery, graphql } from "gatsby"
import ContactForm from "../Forms/Contact/contact-form"

interface ContactProps {}
const Contact: React.FC<ContactProps> = ({}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contactDetails {
              phone {
                name
                link
              }
              email {
                name
                link
              }
              address {
                name
                link
              }
            }
          }
        }
      }
    `
  )
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <div>
          <iframe
            style={{ border: 0, width: "100%", height: "270px" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.527033763313!2d28.222613515184822!3d-25.885039583580898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956616bd3e8ea7%3A0x1885d00960403dd9!2s8%20Jan%20Smuts%20Ave%2C%20Irene%2C%20Centurion%2C%200157!5e0!3m2!1sen!2sza!4v1623184267277!5m2!1sen!2sza"
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>

        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info">
              <a
                href={site.siteMetadata?.contactDetails.address.link}
                style={{ textDecoration: "none" }}
              >
                <div className="address mb-5">
                  <i className="bx bx-map"></i>
                  <h4>Location:</h4>
                  <p>{site.siteMetadata?.contactDetails.address.name}</p>
                </div>
              </a>

              <a
                href={site.siteMetadata?.contactDetails.email.link}
                style={{ textDecoration: "none" }}
              >
                <div className="email mt-5 mb-5">
                  <i className="bx bx-envelope"></i>
                  <h4>Email:</h4>
                  <p>{site.siteMetadata?.contactDetails.email.name}</p>
                </div>
              </a>

              <a
                href={site.siteMetadata?.contactDetails.phone.link}
                style={{ textDecoration: "none" }}
              >
                <div className="phone mt-5">
                  <i className="bx bx-phone-call"></i>
                  <h4>Call:</h4>
                  <p>{site.siteMetadata?.contactDetails.phone.name}</p>
                </div>
              </a>
            </div>
          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
