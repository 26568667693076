import * as React from "react"
import "./contact-form.css"
import { Formik, Form } from "formik"
import InputField from "../Fields/input-field"
import { contactFormSchema } from "./contact-form-schema"
import { submitContactForm } from "./contact-form-factory"
import FormAlert from "../Indicators/form-alert"
import FormLoadingIndicator from "../Indicators/form-loading-indicator"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const handleContactFormSubmission = (response, setStatus, setSubmitting) => {
  if (response.status === 200) {
    setStatus({
      sent: true,
      message: "Your message has been sent. Thank you!",
    })
    setSubmitting(false)
  } else {
    setStatus({
      sent: false,
      message:
        "An error occurred while submitting your form, please try again later",
    })
    setSubmitting(false)
  }
}

const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        emailConfirm: "",
        comment: "",
      }}
      validationSchema={contactFormSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true)
        const response = await submitContactForm(values)
        trackCustomEvent({
          category: "Contact Form Submision Button",
          action: "Click",
          label: "Form Submission",
        })
        handleContactFormSubmission(response, setStatus, setSubmitting)
      }}
    >
      {({ isSubmitting, isValid, status, resetForm }) => (
        <Form id={"contact-form"}>
          <div className="form-row">
            <div className="col-md-6 form-group">
              <InputField
                label={"Full Name"}
                name={"name"}
                placeholder={"Name Surname"}
                type={"text"}
              />
            </div>
            <div className="col-md-6 form-group">
              <InputField
                label={"Phone"}
                name={"phone"}
                placeholder={"000 000 0000"}
                type={"tel"}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 form-group">
              <InputField
                label={"Email"}
                name={"email"}
                placeholder={"example@mail.com"}
                type={"email"}
              />
            </div>
            <div className="col-md-6 form-group">
              <InputField
                label={"Confirm Email"}
                name={"emailConfirm"}
                placeholder={"example@mail.com"}
                type={"email"}
              />
            </div>
          </div>
          <div className="form-group">
            <InputField
              label={"Comment"}
              name={"comment"}
              placeholder={"Ask us something?"}
              as={"textarea"}
              rows={5}
            />
          </div>
          <div className="mb-3">
            {isSubmitting && <FormLoadingIndicator message={"submitting form"}/>}
            {status && status.message && (
              <FormAlert
                status={status}
                onClick={() => {
                  if (status.sent) resetForm()
                }}
              />
            )}
          </div>
          <div className="text-center">
            <button type="submit" disabled={isSubmitting || !isValid}>
              Send Message
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
