import React from "react"
import "./hero.css"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

interface heroProps {}

const Hero: React.FC<heroProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo@0.75x.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)
  return (
    <section id="hero" className="d-flex align-items-center pb-5">
      <div
        className="container position-relative"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9 text-center">
            <StaticImage
              src={"../../images/logo@0.75x.png"}
              className="img-fluid"
              width={400}
              height={400}
              alt="Logo"
            />
          </div>
        </div>
        <div className="row icon-boxes mb-5">
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-stack-line"></i>
              </div>
              <h4 className="title">
                <a href="#about-video" style={{ textDecoration: "none" }}>
                  Social Security and Disaster Relief
                </a>
              </h4>
              <p className="description">
                Food sourcing and delivery, fire and natural disaster relief
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-command-line"></i>
              </div>
              <h4 className="title">
                <a href="#about-video" style={{ textDecoration: "none" }}>
                  Early Childhood Development (ECD)
                </a>
              </h4>
              <p className="description">
                Strengthening Early Childhood Development (ECD) and Child Care
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-palette-line"></i>
              </div>
              <h4 className="title">
                <a href="#about-video" style={{ textDecoration: "none" }}>
                  Gender Based Violence (GBV)
                </a>
              </h4>
              <p className="description">
                Strengthening Prevention and Early Intervention on Gender Based
                Violence (GBV) and Femicide
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-fingerprint-line"></i>
              </div>
              <h4 className="title">
                <a href="#about-video" style={{ textDecoration: "none" }}>
                  Growing and Strengthening NPO sector
                </a>
              </h4>
              <p className="description">
                Training, improving, monitoring and management
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
